<template>
    <table class="table" v-if="summary.length > 0">
        <thead>
        <tr>
            <th>{{ title }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="node in summary" :key="node.id">
            <td width="50%">{{ node.name }}</td>
            <td width="15%">{{ node.quantity }}</td>
        </tr>
        </tbody>
    </table>
</template>

<script>
    import { currency } from '../helpers/currency';

    export default {
        name: 'SummaryTable',
        props: {
            title: String,
            summary: Array,
        },
        methods: {
            currency (value) {
                return currency(value);
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import "../assets/scss/essentials";

    .table {
        td,
        th {
            padding-left: 0;
        }
    }
</style>
