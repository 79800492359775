<template>
    <section class="c-overview">
        <section class="c-overview-intro">
            <div class="c-overview-intro__content">
                <h2 class="alpha">Snel overzicht</h2>
                <h3>Uw selectie</h3>
            </div>
        </section>

        <section class="c-overview-info">
            <div class="c-overview-info__list">
                <summary-table :title="tab.name" v-bind:summary="summary(tab.index)" v-for="tab in tabs" :key="tab.path"></summary-table>
            </div>
        </section>

        <footer class="c-overview-footer">
            <a href="#0" @click="$router.go(-1)" class="o-c2a-link o-c2a-link--tint o-c2a-link--reverse">
                <span class="o-c2a-link__text">Terug naar configuratie</span>
                <span class="o-c2a-link__icon">
                    <span class="o-c2a-link__icon-circle"></span>
                </span>
            </a>
        </footer>

    </section>
</template>

<script>
    import { mapGetters } from 'vuex';
    import SummaryTable from '../components/SummaryTable.vue';

    export default {
        components: { SummaryTable },
        computed: {
            ...mapGetters('summary', {
                summary: 'getSummaryByIndex',
            }),
            ...mapGetters('tabs', {
                tabs: 'getTabs',
            }),
        },
    };
</script>

<style lang="scss" scoped>
    @import "../assets/scss/essentials";

    .c-overview {
        background-color: $white;
        margin-top: rem(-50);
        margin-bottom: rem(-50);
    }

    .c-overview-intro {
        display: grid;
        grid-template-columns: 20% 1fr 20%;
        padding-top: 5%;

        &__content {
            grid-column: 2;
        }
    }

    .c-overview-info {
        display: grid;
        grid-template-columns: 20% 1fr 20%;

        &__list {
            border-top: rem(1) solid $gray-light;
            display: grid;
            grid-column: 2;
            grid-gap: rem(50);
            padding-bottom: 7%;
            padding-top: 7%;
            margin-top: 5%;
        }
    }

    .c-overview-text {
        *:last-child {
            margin-bottom: 0;
        }
    }

    .c-overview-footer {
        @include fixed(left 0 right 0 bottom 0);
        display: grid;
        grid-template-columns: 1fr;
    }
</style>
